*{
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family: 'Montserrat', sans-serif;
}

.name {
  color: #c9e265;
  font-size: 80px;
}